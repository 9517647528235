<template>
  <pricing-packages></pricing-packages>
</template>

<script>
import PricingPackages from '../admin/PricingPackages.vue'
export default {
  components: {
    PricingPackages
  }
}
</script>